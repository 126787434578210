import AbstractMaintain from "../maintenance/AbstractMaintain";
import UsersService from "../../../services/UsersService";

const AdministerUsers = (props) => {
  const columnDefs = [
    {
      Header: "First Name",
      accessor: "firstname",
      type: "text",
      isEditable: true,
      isRequired: true,
    },
    {
      Header: "Last Name",
      accessor: "lastname",
      type: "text",
      isEditable: true,
      isRequired: true,
    },
    {
      Header: "Login",
      accessor: "login",
      type: "text",
      isEditable: true,
      isRequired: true,
    },
    {
      Header: "Is Admin?",
      accessor: "is_admin",
      type: "toggle",
      isEditable: true,
      isRequired: false,
      defaultValue: false,
    },
    {
      Header: "Is Enabled?",
      accessor: "is_enabled",
      type: "toggle",
      isEditable: true,
      isRequired: false,
      defaultValue: true,
    },
    {
      Header: "Password",
      accessor: "setpassword",
      type: "setpassword",
      isEditable: true,
      isRequired: false,
    }
  ];
  return AbstractMaintain(props, {
    columnDefs,
    dataService: UsersService,
    dataDescriptionStr: "Users",
  });
};

export default AdministerUsers;