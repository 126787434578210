import React from "react";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextField from "@mui/material/TextField";

const GJDatePicker = (params) => {

  const {
    title,
    selected,
    onChange,
    readOnly,
    fullWidth=true,
  } = params;

  const _selected = new Date(selected + "T12:00:00Z");
  const _onChange = (date) => {
    if (date) {
      onChange(date.toISOString().split('T')[0]);
    } else {
      onChange(null);
    }
  }

  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }

  const _onKeyDown = (event) => {
    if (!['ArrowUp', 'ArrowDown'].includes(event.key) || !isValidDate(_selected))
      return;
    const newDate = new Date(_selected);
    const change = event.key === 'ArrowUp' ? 1 : -1;
    newDate.setDate(newDate.getDate() + change);
    onChange(newDate.toISOString().split('T')[0]);
  }

  return <LocalizationProvider dateAdapter={AdapterDateFns}>
    <DesktopDatePicker
      label={title}
      inputFormat="MM/dd/yyyy"
      value={_selected}
      onChange={_onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth={fullWidth}
          onKeyDown={_onKeyDown}
          inputProps={
            {
              ...params.inputProps,
              placeholder: "dd/mm/aaaa",
              readOnly: {readOnly}
            }
          }
        />
      )}
    />
  </LocalizationProvider>;
};

export default GJDatePicker;