import RegistriesService from "../../../services/RegistriesService";
import AbstractMaintain from "./AbstractMaintain";

const isValidDate = (dateStr, strict=true) => {
  const regex = strict ? /^\d{4}-\d{2}-\d{2}$/ : /^(\d{4}|\?\?\?\?)-(\d{2}|\?\?)-(\d{2}|\?\?)$/;

  // Check if it matches the pattern
  if (!regex.test(dateStr)) {
    return false;
  }

  const [year, month, day] = dateStr.split('-').map(val => val !== '??' ? Number(val) : val);

  // Check month range
  if (month !== '??' && (month < 1 || month > 12)) {
    return false;
  }

  // Check day range
  if (day !== '??' && (day < 1 || day > 31)) {
    return false;
  }

  // If the string doesn't contain question marks, validate it as an actual date
  if (!dateStr.includes('?')) {
    // JavaScript months are 0-indexed
    const date = new Date(year, month - 1, day);

    // The below conditions check:
    // 1. The date was parsed correctly
    // 2. The parsed month matches the input month (ensures no rollover due to invalid days, e.g. Feb 30)
    // 3. The parsed day matches the input day
    if (isNaN(date) || date.getMonth() + 1 !== month || date.getDate() !== day) {
      return false;
    }
  }

  return true;
}

const yyyymmddValidator = (value, fieldName, strict) => {
  if (!isValidDate(value, strict)) {
    let msg = "'" + fieldName + "' must be formatted 'YYYY-MM-DD'";
    if (!strict) {
      msg += "; use '????' for unknown Year, and '??' for unknown month or day";
    }
    throw msg;
  }
}

// for new entries - left out "Morton News" even though
//   we have some records with this over 6-7 weeks in 1973
const validPublications = ['The Morton News',
  'Tazewell County News', 'Morton Times News', 'Tazewell News', 'Morton Courier', 'Other'];

// const constructPublicationUrl_direct = (publicationData, date, pageNumber = 1) => {
//   const language = 'english';
//   const url = 'https://morton.advantage-preservation.com/viewer/?fn='
//     + publicationData.publicationKey + '_'
//     + date.replace(/-/g, '') + '_' // converts date from YYYY-MM-DD to YYYYMMDD
//     + language + '_'
//     + pageNumber;
//   return <a className="publication-link" target="_blank" rel="noopener noreferrer" href={url}>{publicationData.publicationName}</a>;
// };
const formatDateForSearch = (date) => {
  // convert date from YYYY-MM-DD to m/dd/yyyy
  const dateObj = new Date(date + 'T00:00:00');
  return new Intl.DateTimeFormat('en-US').format(dateObj);
}
const constructPublicationUrl_search = (publicationData, date, lastName) => {
  const searchDate = formatDateForSearch(date);
  const url = 'https://morton.advantage-preservation.com/search'
    + '?k=' + encodeURIComponent(lastName)
    + '&d=' + searchDate + '-' + searchDate
    + '&t=' + publicationData.publicationId
    + '&ord=k1'
  ;
  return <a className="publication-link" target="_blank" rel="noopener noreferrer" href={url}>{publicationData.publicationName}</a>;
};
const PUB_DETAILS = {
  "the morton news": {
    publicationName: 'The Morton News',
    publicationId: 27600,
    publicationKey: 'the_morton_news_usa_illinois_morton',
    first: '1909-01-21',
    last: '1953-12-31',
    stateId: 1508,
    countyId: 1506,
  },
  "tazewell county news": {
    publicationName: 'Tazewell County News',
    publicationId: 27602,
    publicationKey: 'tazewell_county_news_usa_illinois_morton',
    first: '1954-09-09',
    last: '1973-11-05',
    stateId: 1508,
    countyId: 1506,
  },
  "morton news": { // aka "The Tazewell News"
    publicationName: 'The Tazewell News',
    publicationId: 27603,
    publicationKey: 'the_tazewell_news_usa_illinois_morton',
    first: '1973-11-08',
    last: '1973-12-27',
    stateId: 1508,
    countyId: 1506,
  },
  "tazewell news": {
    publicationName: 'Tazewell News',
    publicationId: 27604,
    publicationKey: 'tazewell_news_usa_illinois_morton',
    first: '1974-01-03',
    last: '1997-09-24',
    stateId: 1508,
    countyId: 1506,
  },
  "morton times news": {
    publicationName: 'Morton Times News',
    publicationId: 27606,
    publicationKey: 'morton_times_news_usa_illinois_morton',
    first: '1997-10-01',
    last: '2014-06-25', // this was the last archived date available at time of coding
    stateId: 1508,
    countyId: 1506,
  },
  "morton courier": {
    publicationName: 'Morton Courier',
    publicationId: 31138,
    publicationKey: 'morton_courier_usa_illinois_morton',
    first: '1996-08-14',
    last: '2017-12-27', // this was the last archived date available at time of coding
    stateId: 1508,
    countyId: 1506,
  }
};
const getPublicationLink = (publicationName, date, lastName) => {
  let result = publicationName;
  const publicationData = PUB_DETAILS[publicationName.toLowerCase()];
  if (date >= publicationData.first && ((!'last' in publicationData) || date <= publicationData.last)) {
    // result = constructPublicationUrl_direct(publicationData, date);
    result = constructPublicationUrl_search(publicationData, date, lastName)
  }
  return result;
};

const MaintainRegistries = (props) => {
  const columnDefs = [
    {
      Header: "First Name",
      accessor: "first_name",
      type: "text",
      isEditable: true,
      isRequired: true,
    },
    {
      Header: "Last Name",
      accessor: "last_name",
      type: "text",
      isEditable: true,
      isRequired: true,
    },
    {
      Header: "Middle Name",
      accessor: "middle_name",
      type: "text",
      isEditable: true,
      isRequired: false,
    },
    {
      Header: "Maiden Name",
      accessor: "maiden_name",
      type: "text",
      isEditable: true,
      isRequired: false,
    },
    {
      Header: "Gender",
      accessor: "gender",
      type: "text",
      isEditable: true,
      isRequired: false,
      validator: (value) => {
        if (value !== 'Male' && value !== 'Female') {
          throw "Must be 'Male' or 'Female'";
        }
      },
    },
    {
      Header: "Birth Date",
      accessor: "birth_date",
      type: "text",
      isEditable: true,
      isRequired: true,
      validator: (value) => {
        yyyymmddValidator(value, 'Birth Date', false);
      },
    },
    {
      Header: "Death Date",
      accessor: "death_date",
      type: "text",
      isEditable: true,
      isRequired: true,
      validator: (value) => {
        yyyymmddValidator(value, 'Death Date', false);
      },
    },
    {
      Header: "Age",
      accessor: "age",
      type: "text",
      isEditable: true,
      isRequired: false,
    },
    {
      Header:"Publication",
      accessor:"publication",
      renderCell: (value,rowData) => {
        let result = '';
        if (value) {
          const pubDate = rowData['publication_date'];
          if (pubDate) {
            // display as a link to the publication
            const lastName = rowData['last_name'];
            result = getPublicationLink(value, pubDate, lastName);
          } else {
            result = value;
          }
        };
        return result;
      },
      type:"text",
      isEditable: true,
      isSearchable: true,
      validator: (value) => {
        if (!validPublications.includes(value)) {
          throw validPublications.join(', ');
        }
      }
    },
    {
      Header: "Publication Date",
      accessor: "publication_date",
      type: "text",
      isEditable: true,
      isRequired: true,
      validator: (value) => {
        yyyymmddValidator(value, 'Publication Date', true);
      },
    },
    {
      Header:"City",
      accessor:"city",
      type:"text",
      isEditable: true,
      isSearchable: true,
    },
    {
      Header:"Funeral Home",
      accessor:"funeral_home",
      type:"text",
      isEditable: true,
      isSearchable: true,
    },
    {
      Header:"Comments",
      accessor:"comments",
      type:"text",
      isEditable: true,
      isSearchable: true,
    },
  ];
  return AbstractMaintain(props, {
    columnDefs,
    dataService: RegistriesService,
    dataDescriptionStr: "Registry",
  });
};

export default MaintainRegistries;
