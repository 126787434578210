import AbstractService from "./AbstractService";

class BuildingsService extends AbstractService {

  constructor() {
    super("registries");
  }

}

export default new BuildingsService();