import {Navigate} from "react-router-dom";
import {useAuth} from "../../../utils/useAuth";
import ProfileMenu from "../../templates/ProfileMenu";

const adminMenuStyle = {
  position: 'fixed',
  top: '5px',
  right: '5px',
};

export const ProtectedRoute = ({children}) => {
  const {user} = useAuth();
  if (!user) {
    return <Navigate to="/login" />;
  }
  return children;
};

export const AuthMenuWrapper = ({children}) => {
  return <div>
    <div style={adminMenuStyle}>
      <ProfileMenu />
    </div>
    {children}
  </div>;
};
