// wraps the "MaintainRegistries" component, adds an admin button if user is logged in
import {MaintainRegistries} from "./index";
import ProfileMenu from "../../templates/ProfileMenu";
const adminMenuStyle = {
  position: 'fixed',
  top: '5px',
  right: '5px',
};

const MaintainRegistriesAuthWrapper = (props) => {
  return (
    <div>
      <div style={adminMenuStyle} >
        <ProfileMenu/>
      </div>
      <MaintainRegistries/>
    </div>
  );
};

export default MaintainRegistriesAuthWrapper;