import React from "react";
import Pagination from '@mui/material/Pagination';
import TablePagination from '@mui/material/TablePagination';

const GJPagination = (params) => {

  const {
    rowIndex,
    totalRowCount,
    rowsPerPage,
    setRowIndex,
    setRowsPerPage,
    isLoading,
    doTriggerReload,
  } = params;

  const ROWS_PER_PAGE_OPTIONS = [10,25,50,100];
  const DEFAULT_ROWS_PER_PAGE = 10;

// page is zero-based index
  const page = Math.floor(rowIndex / rowsPerPage);

  const handleChangePage = (event, pageNumber) => {
    // again, zero-based index
    const newRowIndex = pageNumber * rowsPerPage;
    if (newRowIndex > (totalRowCount-1)) {
      throw "Unexpected rowIndex OutOfBounds (" + newRowIndex + "/" + (totalRowCount-1) + ")";
    }
    setRowIndex(newRowIndex);
    doTriggerReload();
  };
  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, DEFAULT_ROWS_PER_PAGE);
    setRowsPerPage(newRowsPerPage);
    setRowIndex(0);
    doTriggerReload();
  };

  if (isLoading) {
    return <div>Loading...</div>;
  } else {
    return <TablePagination component="div"
                            count={totalRowCount}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            showFirstButton={true}
                            showLastButton={true}
                            style={{display:"flex"}}
    />;
  }
};

export default GJPagination;