import React from 'react';
import {HashRouter,Routes,Route} from "react-router-dom";

// import './App.css';
import TopNav from "./components/templates/TopNav";
// import Home from "./components/pages/home/Home";
// import Login from "./components/pages/auth/Login";
import {Maintenance, MaintenanceMenu, MaintainRegistries, MaintainRegistriesAuthWrapper} from "./components/pages/maintenance";
import AdministerSettings from "./components/pages/admin/AdministerSettings";
import AdministerUsers from "./components/pages/admin/AdministerUsers";

// import {ProtectedRoute} from "./components/pages/auth/ProtectedRoute";
import {AuthProvider} from "./utils/useAuth";

import {ProtectedRoute,AuthMenuWrapper,Login} from "./components/pages/auth";

const protect = (module) => {
  return <ProtectedRoute>{module}</ProtectedRoute>;
};
const addAuthMenu = (module) => {
  return <AuthMenuWrapper>{module}</AuthMenuWrapper>;
}

function App() {
  return (
    <React.StrictMode><HashRouter><AuthProvider>
      <Routes>
        <Route path="/">
          <Route index element={<MaintainRegistriesAuthWrapper/>}/>
          <Route path="login" element={<Login/>} />
          <Route path="profile" element={protect(addAuthMenu(<AdministerSettings/>))} />
          <Route path="administerusers" element={protect(addAuthMenu(<AdministerUsers/>))} />
        </Route>
      </Routes>
    </AuthProvider></HashRouter></React.StrictMode>
  );
}
export default App;