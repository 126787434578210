import GJTextField from "../../../UI/organisms/GJTextField";
import React from "react";

const TextInput = ({title,index,value,setValue,isPassword,onKeyDown,autoFocus}) => {
  const params = {title,index,value,
    setValue:e=>setValue(e.target.value),
    fullWidth:false,
    style:{minWidth: "90%", marginTop:"10px"},
    type:isPassword?"password":null,
    onKeyDown,
    autoFocus,
  };
  return <div>
    <GJTextField {...params} />
  </div>;
};

export default TextInput;