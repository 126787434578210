import React from 'react';
import {Outlet} from "react-router-dom";
import NavMenu from "../../UI/molecules/NavMenu";
import MenuItem from "../../UI/atoms/MenuItem";

const WrappedOutlet = Outlet;

function MaintenanceMenu() {
  return (
    <div>
      <NavMenu>
        <MenuItem path="/maintenance/registries" text="Registries" header="Maintain Registries" />
      </NavMenu>
      <WrappedOutlet />
    </div>
  );
}

export default MaintenanceMenu;