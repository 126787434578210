import React, { useEffect, useState, useMemo, useRef } from "react";
import { useTable } from "react-table";

import {useAuth} from "../../../utils/useAuth";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputValidator from '../../../utils/InputValidator';
import Table from './TableBuilder';
import "react-datepicker/dist/react-datepicker.css";

import GJSelect from "../../UI/organisms/GJSelect";
import GJSearchSelect from "../../UI/organisms/GJSearchSelect";
import GJTextField from "../../UI/organisms/GJTextField";
import GJDatePicker from "../../UI/organisms/GJDatePicker";
import NamingThings from "../../../utils/NamingThings";
import SearchHelper from "../../../utils/SearchHelper";
import GJSmartSelect from "../../UI/organisms/GJSmartSelect";
import GJToggle from "../../UI/organisms/GJToggle";
import GJPagination from "../../UI/organisms/GJPagination";
import DeleteConfirmationDialog from "../../UI/molecules/DeleteConfirmationDialog";

const AbstractMaintain = (props, {
    columnDefs,
    dataService,
    dataDescriptionStr = 'unknown',
    importerConfig = null,
    allowDelete = false,
}) => {
  const _useState = (defaultValue,name,doLogUsage=false) => {
    const [value, setter] = useState(defaultValue);
    useEffect(()=>{
      if (doLogUsage) {
        console.log(name + ' changed: ',value);
        console.log('...theAddModalData: ', theAddModalData);
      }
    },[value]);
    let increment;
    if (value === 0) {
      increment = n => n+1;
    }
    const _setter = val => {
      let resultVal = val;
      if (typeof val === 'function') {
        resultVal = val(value);
      }
      if (doLogUsage) {
        console.log('_useState - setting ' + name + ' to: ', resultVal);
        console.log(new Error('stacktrace'));
      }
      setter(resultVal);
      if (doLogUsage) {
        console.log('_useState - result of ' + name + ': ', value);
      }
      // return result;
    }
    return [value, _setter, increment];
  };
  const [data, setData] = _useState([],'data');
  // total count of results without limit and offset applied
  const [dataCount, setDataCount] = _useState(0, 'dataCount');
  const [isDataLoading, setIsDataLoading] = _useState(true,'isDataLoading');
  const [editRowIndex, setEditRowIndex] = _useState(null,'editRowIndex');
  const [isSaving, setIsSaving] = _useState(false,'isSaving');
  const [origRow, setOrigRow] = _useState(null,'origRow');
  const [isTheAddModalOpen, setIsTheAddModalOpen] = _useState(false,'isTheAddModalOpen');
  const [theAddModalData, setTheAddModalData] = _useState({},'theAddModalData');
  const [validationResults, setValidationResults] = _useState({},'validationResults');
  const [lookupData, setLookupData] = _useState({},'lookupData');
  const [isLookupDataLoading, setIsLookupDataLoading] = _useState(false,'isLookupDataLoading');
  const [pageMessage, setPageMessage] = _useState("",'pageMessage');
  const [pageMessageStyle, setPageMessageStyle] = _useState({display:"none"},'pageMessageStyle');
  const [modalMessage, setModalMessage] = _useState("",'modalMessage');
  const [modalMessageStyle, setModalMessageStyle] = _useState({display:"none"},'modalMessageStyle');
  const [isImportModalOpen, setIsImportModalOpen] = _useState(false,'isImportModalOpen');
  const [isFilePicked, setIsFilePicked] = _useState(false,'isFilePicked');
  const [importedJson, setImportedJson] = _useState(null,'importedJson');
  const [forceReloadAllData, setForceReloadAllData] = _useState(0,'forceReloadAllData');
  const [forceReloadLookupData, setForceReloadLookupData] = _useState(0,'forceReloadLookupData');
  const [searchQuery, setSearchQuery] = _useState("", 'searchQuery');
  const [lazyloadIsLoading, setLazyloadIsLoading] = _useState(false, 'lazyloadIsLoading');
  const [lazyloadRowId, setLazyloadRowId] = _useState(null, 'lazyloadRowId');
  const [lazyloadColumnName, setLazyloadColumnName] = _useState(null, 'lazyloadColumnName');
  const [dataResultStartIndex, setDataResultStartIndex] = _useState(0, 'dataResultStartIndex');
  const [dataResultRowsPerPage, setDataResultRowsPerPage] = _useState(10, 'dataResultRowsPerPage');
  const [forcePostProcessing, setForcePostProcessing] = _useState(0, 'forcePostProcessing');

  const pagination = !dataCount ? null :
    <GJPagination rowIndex={dataResultStartIndex}
                  totalRowCount={dataCount}
                  rowsPerPage={dataResultRowsPerPage}
                  setRowIndex={setDataResultStartIndex}
                  setRowsPerPage={setDataResultRowsPerPage}
                  isLoading={isDataLoading}
                  doTriggerReload={()=>{setForceReloadAllData(n => n+1)}}
                  sx={{align:'left'}}
  />;


  const isEditMode = useMemo(
    () => {
      return null != editRowIndex
    },
    [editRowIndex]
  );


  // This `useRef` hook lets us store a mutable (changeable) value
  // that will persist values between renders, but itself does not
  // cause a re-render when updated.
  const dataRef = useRef();
  dataRef.current = data;

  // These are some simple methods to convert dates back and forth between the Date objects that
  // datepicker uses and the format that the database requires
  const date_to_string = (date:Date) => {
    return date.toISOString().split('T')[0];
  }

  const string_to_date = (string:String) => {
    let temp = new Date();
    let year = parseInt(string.substr(0,4));
    let month = parseInt(string.substr(5,2)) -1;
    let day = parseInt(string.substr(8, 2));
    let offset = temp.getTimezoneOffset();

    return new Date(Date.UTC(year, month, day, 0, offset, 0, 0));
  }

  const _dataLookupFilters = () => {
    const filters = {
      offset: dataResultStartIndex,
      limit: dataResultRowsPerPage,
    };
    if (searchQuery) {
      filters.qq = encodeURIComponent(searchQuery);
    }
    return filters;
  };

  // This `useEffect` hook will do an initial data load when this component is
  // displayed, and will set the result on the state, causing re-render.
  useEffect( ()=> {
    // console.log('useEffect loadData');
    const loadData = async () => {
      setIsDataLoading(true);
      // const result = await dataService.get();
      const result = await dataService.getWithRelations(null,_dataLookupFilters(),true);
      setData(result.data.data);
      setDataCount(result.data.count);
      setIsDataLoading(false);
    }
    loadData().catch(console.error);
  }, [forceReloadAllData] );

  // This `useEffect` hook will fetch data needed by the tables' <select> inputs
  useEffect( ()=> {
    // console.log('useEffect loadLookupData');
    if (forceReloadLookupData == 0) {
      return; // without loading
    }
    const loadLookupData = async () => {
      setIsLookupDataLoading(true);
      const newLookupData = {};
      for (const columnDef of columnDefs) {
        const key = columnDef.accessor;
        let wasLookup = false;
        if (columnDef.lookupFunction) {
          const dataToUse = (isTheAddModalOpen) ? theAddModalData : null;
          const lookupData = await columnDef.lookupFunction(dataToUse);
          newLookupData[key] = lookupData.data;
          // console.log('result was', lookupData.data);
          wasLookup = true;
        } else if (columnDef.lookupService) {
          const lookupData = await columnDef.lookupService.get();
          newLookupData[key] = lookupData.data;
          wasLookup = true;
        }
        // clear out any data or input data that is not valid for the new lookup data
        if (wasLookup) {
          const validValuesArray = newLookupData[key];
          const processKey = oldData => {
            const newData = {...oldData};
            if (columnDef.forceValidSelectionOnLookup && validValuesArray && validValuesArray.length > 0) {
              newData[key] = validValuesArray[0];
            } else {
              delete newData[key];
            }
            return newData;
          };
          if (theAddModalData) {
            if (theAddModalData[key]) {
              const currentVal = theAddModalData[key];
              let isValid = false;
              for (const option of validValuesArray) {
                if (option.id == currentVal.id) {
                  isValid = true;
                  break;
                }
              }
              if (!isValid) {
                setTheAddModalData(processKey);
              }
            } else if (columnDef.forceValidSelectionOnLookup) {
              setTheAddModalData(processKey);
            }
          }
        }
      }
      setLookupData(newLookupData);
      setIsLookupDataLoading(false);
    };
    loadLookupData().catch(console.error);
  }, [forceReloadLookupData]);

  useEffect( () => {
    if (isTheAddModalOpen && forceReloadLookupData == 0) {
      setForceReloadLookupData(1);
    }
  }, [isTheAddModalOpen]);

  useEffect( () => {
    if (!lazyloadIsLoading) {
      return;
    }
    const doLazyload = async () => {
      const result = (await dataService.lazyload(lazyloadRowId, lazyloadColumnName))['data'][0];
      // find matching row in dataset
      const copyOfData = [...data];
      let wasUpdated = false;
      for (let i=0; i<copyOfData.length; i++) {
        const datum = copyOfData[i];
        if (datum.id === lazyloadRowId) {
          for (const [key,value] of Object.entries(result)) {
            datum[key] = value;
            wasUpdated = true;
          }
        }
      }
      if (wasUpdated) {
        setData([...copyOfData]);
      }
      setLazyloadRowId(null);
      setLazyloadColumnName(null);
      setLazyloadIsLoading(false);
    };
    doLazyload().catch(console.error);
  }, [lazyloadIsLoading]);

  useEffect( () => {
    if (forcePostProcessing === 0) {
      return;
    }
    const doPostProcessing = async () => {
      for (const columnDef of columnDefs) {
        if (columnDef['postProcessor'] && typeof columnDef['postProcessor'] === 'function') {
          const newData = await columnDef['postProcessor'](theAddModalData,lookupData);
          if (newData !== theAddModalData) {
            setTheAddModalData(newData);
            if (columnDef.triggerLookupRefreshOnChange) {
              setForceReloadLookupData(n => n+1);
            }
          }
        }
      }
    };
    doPostProcessing().catch(console.error);
  }, [forcePostProcessing]);

  const isDataValid = (obj) => {
    let validationErrors = null;
    for (const columnDef of columnDefs) {
      const key = columnDef.accessorOverride || columnDef.accessor;
      if (typeof obj[key] == Date) {
        obj[key] = date_to_string(obj[key]);
      }
      try {
        obj[key] = InputValidator.validate(columnDef, obj, key);
      } catch (error) {
        if (!validationErrors) {
          validationErrors = {};
        }
        validationErrors[key] = error;
      }
    }
    if (validationErrors) {
      setValidationResults(validationErrors);
      return false;
    } else {
      setValidationResults({});
      return true;
    }
  };

  //Leave the modal open, set the cursor back in the first field
  const handleModalSaveAndNew = async () => {
    await handleSave(
      () => {
        setIsTheAddModalOpen(false);
        setTimeout(()=>setIsTheAddModalOpen(true),1);
      }
    ,true);
    document.getElementById("addeditmodal").querySelector("input").focus()
  }

  //Close the modal
  const handleModalSave = async () => {
    await handleSave(() => setIsTheAddModalOpen(false));
  };

  const handleSave = async(callback, isSaveAndNew=false) => {
    const newObject = {...theAddModalData};
    // console.log('trying to save (newObject): ', newObject);
    // convert selection objects to id values
    for (const columnDef of columnDefs) {
      const hasAccessorId = newObject[columnDef.accessor] && newObject[columnDef.accessor]['id'];
      const hasOverrideId = newObject[columnDef.accessorOverride] && newObject[columnDef.accessorOverride]['id'];
      if ((columnDef.type == 'select'
            || columnDef.type == 'smartselect'
            || columnDef.type == 'searchselect'
        )
          && (hasAccessorId || hasOverrideId))
      {
        if (hasOverrideId) {
          newObject[columnDef.accessorOverride] = newObject[columnDef.accessorOverride].id;
          delete newObject[columnDef.accessor];
        } else {
          newObject[columnDef.accessor] = newObject[columnDef.accessor].id;
        }
      }
    }
    // 1. validate the inputs
    if (!isDataValid(newObject)) {
      console.log('something is invalid',validationResults);
      return;
    }

    // 2. call "create" method on API / DAO
    // TODO do something different for mode === 'edit' (versus 'add')
    setIsSaving(true);
    try {
      const updateMode = newObject.id;
      let response;
      if (updateMode) {
        await dataService.save(newObject.id, newObject);
      } else {
        response = await dataService.create(newObject);
        newObject.id = response.data.insertId;
      }
      const newObjectWithRelations = await dataService.getWithRelations(newObject.id);
      if (updateMode) {
        replaceDataElement(newObjectWithRelations.data, editRowIndex);
      } else {
        addDataElement(newObjectWithRelations.data);
      }
      setTheAddModalData({});
      setIsSaving(false);
      typeof callback === 'function' && callback();
      const doSetMessage = isSaveAndNew ? doSetModalMessage : doSetPageMessage;
      doSetMessage("Successfully saved " + ((updateMode) ? "" : "new ") + dataDescriptionStr);
      setTimeout(()=>{doSetMessage(null)},5000);
    } catch (error) {
      console.log(error);
      const reason = error.response?.data?.message;
      let msg = "There was an error saving data, check logs for details";
      if (reason && reason.startsWith("Duplicate entry")) {
        try {
          const parts = reason.replaceAll("'","").split('unique__')[1].split('__').splice(1);
          msg = "A " + dataDescriptionStr + " already exists with the same '" + parts.join(', ') + "'";
        } catch (e2) {
          // ignore, go with orig message
        }
      }
      doSetModalMessage(msg, true);
      setIsSaving(false);
    }
  }

  const handleChangingDataInTheAddModal = (columnDef, newValue, type) => {
    const _accessor = columnDef.accessorOverride ? columnDef.accessorOverride : columnDef.accessor;
    setTheAddModalData(oldState => {
      const newState = {};
      newState[_accessor] = newValue;
      const result = {
        ...oldState,
        ...newState,
      };
      // see if any overrides need to be applied
      for (const [key,value] of Object.entries(result)) {
        for (const cDef of columnDefs) {
          if (cDef.accessor === key && cDef.applyValueOverride) {
            result[key] = cDef.applyValueOverride(result, value);
            if (!result[key]) {
              delete result[key];
            }
          }
        }
      }
      if (columnDef['postProcessor']) {
        setForcePostProcessing(n => n+1);
      }
      return result;
    });
    if (columnDef.triggerLookupRefreshOnChange) {
      setForceReloadLookupData(n => {
        // console.log('forceReloadLookupData',n+1);
        return n+1;
      });
    }
  };

  const _evaluateColumnDef = (columnDef, propertyName, defaultValue = true) => {
    let result = defaultValue;
    const val = columnDef[propertyName];
    if (null != val) {
      if (typeof val === 'function') {
        if (theAddModalData) {
          result = val(theAddModalData);
          if (result == null) {
            result = false;
          }
        }
      } else {
        result = val;
      }
    }
    // console.log(columnDef.accessor + ' ' + propertyName + ' evaluates to ', result);
    return result;
  }
  const _isRequired = (columnDef) => {
    return _evaluateColumnDef(columnDef, 'isRequired');
  };
  const _isVisible = (columnDef) => {
    return _evaluateColumnDef(columnDef, 'isVisible');
  }
  const _isEditable = (columnDef) => {
    return _evaluateColumnDef(columnDef, 'isEditable');
  }

  const _findInputValue = (existingValue, columnDef) => {
    let result = existingValue;
    if (columnDef.applyValueOverride) {
      // console.log('trying override on value', result);
      result = columnDef.applyValueOverride(theAddModalData, result);
    } else if (!result) {
      if (columnDef.type === "select") {
        const selectOptions = lookupData[columnDef.accessor];
        // console.log(columnDef.accessor + ' selectOptions', selectOptions);
        if (selectOptions && selectOptions.length > 0) {
          result = selectOptions[0];
        } else {
          result = columnDef.defaultValue || null;
        }
      } else {
        result = columnDef.defaultValue || "";
      }
    }
    // console.log('_findInputValue ' + columnDef.accessor, result);
    return result;
  };


  const renderDataInputField = (columnDef, loopIndex) => {
    if (!_isVisible(columnDef)) {
      return null;
    }
    if (columnDef.type === 'derived') {
      // derived fields are not editable, for now
      return null;
    }
    const label = columnDef.Header + (_isRequired(columnDef) ? "*" : "");
    const errorMessage = validationResults[columnDef.accessor];
    const readOnly = !_isEditable(columnDef);

    const key = NamingThings.buildKey("modalItem",label,loopIndex);
    let renderedField;
    const globalProps = {
      key: key,
      index: loopIndex,
      title: label,
      label: label,
      errorMessage: errorMessage,
      readOnly: readOnly,
    };
    switch (columnDef.type) {
      case "smartselect":
        const _accessor = columnDef.accessorOverride ? columnDef.accessorOverride : columnDef.accessor;
        const _doSetValue = (value) => {
          handleChangingDataInTheAddModal(columnDef,value,'smartSelectOnChange');
        };
        renderedField = <GJSmartSelect
          {...globalProps}
          config={columnDef['smartSelectConfig']}
          value={theAddModalData[_accessor]}
          setValue={_doSetValue}
        />;
        break;
      case "text":
      case "derived":
      case "expandingviewer":
      case "setpassword":
        renderedField = <GJTextField
          {...globalProps}
          value={theAddModalData[columnDef.accessor] || ""}
          setValue={(event)=>handleChangingDataInTheAddModal(columnDef,event.target.value,'textFieldOnChange')}
        />;
        break;
      case "yyyymmdd":
        renderedField = <GJDatePicker
          {...globalProps}
          selected={theAddModalData[columnDef.accessor] || ""}
          onChange={(date)=>handleChangingDataInTheAddModal(columnDef,date,'datePickerOnChange')}
        />;
        break;
      case "searchselect":
        const onSelected = (selectedId) => {
          handleChangingDataInTheAddModal(columnDef,selectedId);
        };
        const preloadId = isEditMode && theAddModalData
          && theAddModalData[columnDef.accessor]
          && theAddModalData[columnDef.accessor]['id']
          ? theAddModalData[columnDef.accessor]['id'] : null;
        renderedField = <GJSearchSelect dataService={columnDef.lookupService}
                                        {...globalProps}
                                        optionMapFunction={columnDef.optionMapFunction}
                                        searchSelectionMatcher={columnDef.searchSelectionMatcher}
                                        onSelected={onSelected}
                                        preloadId={preloadId}
        />;
        break;
      case "select":
        const theData = lookupData[columnDef.accessor];
        const valueToUse = _findInputValue(theAddModalData[columnDef.accessor],columnDef);
        const selectOptions = (readOnly && !valueToUse) ? [] : lookupData[columnDef.accessor];
        if (theData) {
          renderedField =
            <GJSelect
              {...globalProps}
              selectOptions={selectOptions}
              value={valueToUse}
              setValue={(val)=>handleChangingDataInTheAddModal(columnDef,val,'autocompleteOnChange')}
              optionFilterColumn={columnDef.optionFilterColumn}
            />;
        } else {
          renderedField = <div key={NamingThings.buildKey("select_key",label,loopIndex)}>Loading {columnDef.Header} data...</div>;
        }
        break;
      case "toggle":
        let currentVal = !!theAddModalData[columnDef.accessor]
        renderedField = <GJToggle checked={currentVal}
                                  {...globalProps}
                                  handleChange={val=>handleChangingDataInTheAddModal(columnDef,!currentVal,'toggleChange')}
        />;
        break;
      default:
        renderedField = <div key={NamingThings.buildKey("unknown_key",label,loopIndex)}>Unknown data type: {columnDef.type}</div>;
        break;
    }
    return <div key={"renderedInput_" + loopIndex} style={{padding:"10px 0px"}}>{renderedField}</div>;
  };
  const handleModalClose = () => {
    setIsTheAddModalOpen(false);
    setIsImportModalOpen(false);
    cancelEdit();
    clearAll();
  };

  const submitNewImport = async () => {
    let errorMsg = null;
    try {
      const result = await importerConfig.importJson(importedJson);
      if (result.data.status == 'FAILED') {
        errorMsg = "Encountered error(s): ";
        for (let i=0; i<result.data.errors.length; i++) {
          errorMsg += result.data.errors[i] + "; ";
        }
      }
    } catch (error) {
      console.log('error importing', error);
      errorMsg = "Encountered an error importing data, check logs for details";
    }
    if (errorMsg) {
      doSetModalMessage(errorMsg, true);
    } else {
      setForceReloadAllData(n => n+1);
      handleModalClose();
      doSetPageMessage("Imported " + importedJson.length + " records");
    }

  };

  const handleCancelNewImport = () => {
    setIsImportModalOpen(false);
    clearAll();
  };
  const readImportedFile = (event) => {
    setIsFilePicked(true);
    event.preventDefault();
    if (event.target.files) {
      importerConfig.convertFileToJson(event.target.files[0], (json) => {
        // console.log('converted to json:', json);
        setImportedJson(json);
      });
    }
  } //adapted from https://dev.to/shareef/convert-excel-to-json-in-reactjs-in-just-2-steps-4pa1

  const dataInputFields = columnDefs.map(
    (columnDef, loopIndex) => renderDataInputField(columnDef, loopIndex)
  );

  const dialogTitle = ((isTheAddModalOpen && isEditMode)
    ? 'Edit ' : 'Add New ') + dataDescriptionStr;
  const saveAndNewButton = isEditMode ? null :
    <Button onClick={handleModalSaveAndNew}>Save And New</Button>;
  const addItemDialog = (!isTheAddModalOpen) ? null :
    <Dialog open={isTheAddModalOpen}
            maxWidth='md'
            fullWidth
            id="addeditmodal"
    >
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <div style={modalMessageStyle}>{modalMessage}</div>
        {dataInputFields}
      </DialogContent>
      <DialogActions>
        {saveAndNewButton}
        <Button onClick={handleModalSave}>Save</Button>
        <Button onClick={handleModalClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  ;

  const fileTypeAccepts = (importerConfig) ? importerConfig.fileTypeAccepts : '.csv';
  const importedData = (importedJson) ? importedJson : [{}];
  const importedMsg = ((importedJson) ? importedJson.length : '0') + " records ready for import";
  const submitButtonStyle = (importedJson) ? {} : {display:"none"};
  const importItemDialog = (!isImportModalOpen) ? null :
    <Dialog open={isImportModalOpen}
            maxWidth='lg'
            fullWidth
    >
      <DialogTitle>Import {dataDescriptionStr}s</DialogTitle>
      <DialogContent>
        <input type="file" name="file_input" id="file_input" accept={fileTypeAccepts} onChange={readImportedFile}/>
        <div style={modalMessageStyle}>{modalMessage}</div>
        {isFilePicked ? (
          <div style={{paddingTop:"10px"}}>
            <span>{importedMsg}</span>
            <Table data={importedData} />
          </div>
        ) : (
          <p>Choose a file to see info</p>
        )}
      </DialogContent>
      <DialogActions>
        <Button style={submitButtonStyle} onClick={submitNewImport}>Import These {dataDescriptionStr}s</Button>
        <Button onClick={handleCancelNewImport}>Cancel</Button>
      </DialogActions>
    </Dialog>
  ;

  const replaceDataElement = (element, rowIndex) => {
    setData(existingData => {
      return existingData.map((p, loopIndex) => {
        return loopIndex == rowIndex ? element : p;
      });
    });
  };

  const addDataElement = (element) => {
    setData(existingData => {
      return [...existingData, element];
    });
  };

  const handleDeleteRow = async (rowIndex) => {
    const currentData = dataRef.current[rowIndex];
    setIsSaving(true);
    let hasError = false;
    try {
      const result = await dataService.delete(currentData['id']);
      if (result['status'] && result['status'] >= 400) {
        hasError = true;
        console.error(result);
      }
    } catch (error) {
      hasError = true;
      console.error(error);
    }
    if (hasError) {
      doSetPageMessage('There was an error deleting data, check browser console log for details', true);
    } else {
      doSetPageMessage('Successfully deleted the record');
    }
    setIsSaving(false);
    setForceReloadAllData(n => n+1);
  };

  const handleEditRow = (rowIndex) => {
    const currentData = dataRef.current[rowIndex];
    setOrigRow({...currentData});
    setEditRowIndex(rowIndex);
    clearAll();
    // do lookups
    setForceReloadLookupData(n => n+1);
    // set active modal data from row
    setTheAddModalData({...currentData});
    // display modal
    setIsTheAddModalOpen(true);
  };

  const cancelEdit = () => {
    replaceDataElement(origRow, editRowIndex);
    setOrigRow(null);
    setEditRowIndex(null);
    clearAll();
  }

  const renderColumn = (columnDef) => {
    const renderedColumn = {
      Header: columnDef.Header,
      accessor: columnDef.accessor,
      Cell: (props) => {
        // console.log('rendering Cell for props: ', props);
        const rowIndex = props.row.index;
        const rowData = props.data[rowIndex];
        let value = props.value;
        if (value) {
          if (value.derivedName) {
            value = value.derivedName;
          } else if (value.name) {
            value = value.name;
          }
        }
        if (columnDef.lazyload && !value) {
          if (lazyloadIsLoading) {
            value = "Loading...";
          } else {
            const doLazyLoad = () => {
              setLazyloadRowId(props.row.original.id);
              setLazyloadColumnName(columnDef.accessor);
              setLazyloadIsLoading(true);
            }
            value = <Button onClick={doLazyLoad}>Load</Button>;
          }
        } else if (columnDef.renderCell) {
          value = columnDef.renderCell(value,rowData);
        }
        return (
          <div>{value}</div>
        );
      }
    };
    return renderedColumn;
  };

  const renderedColumns = [];
  columnDefs.forEach(columnDef => {
    renderedColumns.push(renderColumn(columnDef));
  });

  // This `useMemo` hook will cause the result of this calculation to be cached,
  // and this will only be re-calculated (causing re-render) when a dependency
  // in the 2nd-parameter array is modified.
  const {user} = useAuth();
  const columns = useMemo(
    () => {
      if (!user) {
        // user is not logged in, do not show "Edit"
        return renderedColumns;
      }
      return [
        {
          Header: "Edit",
          Cell: (props) => {
            const rowIndex = props.row.id;
            let deleteIcon = null;
            if (allowDelete) {
              deleteIcon = <DeleteConfirmationDialog
                message="Are you sure you want to delete this record?"
                handleCancel={()=>{/* do nothing */}}
                handleConfirm={()=>handleDeleteRow(rowIndex)}
              />;
            }
            return <div>
              <span style={{cursor:"pointer"}} onClick={() => handleEditRow(rowIndex)}>
                <img src="/icons/edit_001.png" width="16" height="16" alt="Edit Record"/>
              </span>
              {deleteIcon}
            </div>;
          }
        },
        ...renderedColumns,
      ];
    },
    [data,allowDelete,user]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
    // initialState,
    initialState: {}
  });

  let tableBody;
  // these need to be called after the react hooks (useMemo, useTable)
  if (isDataLoading || (isLookupDataLoading && editRowIndex != null)) {
    // console.log('isDataLoading', isDataLoading);
    // console.log('isLookupDataLoading', isLookupDataLoading);
    tableBody = (
      <tr>
        <td colSpan={columnDefs.length}>Loading...</td>
      </tr>
    );
  } else if (data.length === 0) {
    tableBody = (
      <tr>
        <td colSpan={columnDefs.length}>No Data Available</td>
      </tr>
    );
  } else {
    tableBody =
      rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                );
              })}
            </tr>
          );
        });
  }

  const doSetPageMessage = (message, isError=false) => {
    _doSetMessage(message, isError, setPageMessage, setPageMessageStyle);
  };
  const doSetModalMessage = (message, isError=false) => {
    _doSetMessage(message, isError, setModalMessage, setModalMessageStyle);
  };
  const _doSetMessage = (message, isError, setMsg, setStyle) => {
    if (message === null) {
      setMsg("");
      setStyle({display:"none"});
      return;
    }
    setMsg(message);
    const bgColor = isError ? "pink" : "palegreen";
    const style = {
      backgroundColor: bgColor,
      width: "80%",
      paddingLeft: "10px",
      margin: "20px",
    };
    setStyle(style);
  };

  const clearEditing = () => {
    if (isEditMode) {
      cancelEdit();
    }
  };
  const handleAddButtonClick = async () => {
    clearEditing();
    // setTheAddModalData using default values
    const newData = {};
    for (const columnDef of columnDefs) {
      let lookupData;
      if (columnDef.lookupFunction) {
        lookupData = (await columnDef.lookupFunction(newData))['data'];
        if (Array.isArray(lookupData) && lookupData.length > 0) {
          lookupData = lookupData[0];
        }
      } else if (columnDef.defaultValue) {
        if (columnDef.lookupService) {
          lookupData = (await columnDef.lookupService.get(columnDef.defaultValue))['data'];

        } else {
          lookupData = columnDef.defaultValue;
        }
      }
      if (lookupData) {
        newData[columnDef.accessor] = lookupData;
      }
    }
    setTheAddModalData(newData);
    setIsTheAddModalOpen(true);
    clearAll();
  };

  const handleSearchChange = (event) => {
    let searchContents = event.target.value;
    setSearchQuery(searchContents);
    setDataResultStartIndex(0);
    setForceReloadAllData(n => n+1);
    return searchQuery;
  };

  const addButton = (user) ?
    <div>
      <Button value="Add"
              onClick={handleAddButtonClick}
      >Add</Button>
    </div>
    : null;
  const topLine = (
    <div>
      <div style={{display:"flex",paddingTop:"10px"}}>
        {addButton}
        <div>
          <input id = "searchBox"
                 value = {searchQuery}
                 onChange={handleSearchChange}
                 placeholder="Search"
          />
        </div>
        <div style={pageMessageStyle}>{pageMessage}</div>
      </div>
      {pagination}
    </div>
  );

  const clearAll = () => {
    setPageMessage("");
    setPageMessageStyle({display:"none"});
    setModalMessage("");
    setModalMessageStyle({display:"none"});
    setValidationResults({});
    setIsFilePicked(false);
    setLookupData({});
  };

  const breakpoint = 'here';
  return (
    <div>
      {topLine}
      {addItemDialog}
      {importItemDialog}
      <table {...getTableProps()}>
        <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
        </thead>
        <tbody {...getTableBodyProps()}>
        {tableBody}
        </tbody>
      </table>
      {pagination}
    </div>
  );
}

export default AbstractMaintain;
